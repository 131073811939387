/**
 * @description 导出网络配置
 */

////----------------------------------------------------------》》》》准生产环境接口访问配置《《《《------------------------------------------------------------------------------////
/**
 * 准生产环境 接口请求地址
 * @type {{
 * baseURL: string,     业务访问接口
 * webscoket: string,   即时通讯连接地址
 * cloudUrl: string,    云存储上传下载
 * aiPictureUrl: string AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 */
// const requestAddrGather = {
//   baseURL: 'http://1.82.253.168:29000',
//   webscoket: 'ws://1.82.253.168:9600',
//   cloudUrl: 'http://1.82.253.168:29601',
//   aiPictureUrl: 'http://1.82.253.168:9600',
// }

////----------------------------------------------------------》》》》开发环境接口访问配置《《《《------------------------------------------------------------------------------////
/**
 * 开发环境 接口请求地址
 * @type {{
 * baseURL: string,     业务访问接口
 * webscoket: string,   即时通讯连接地址
 * cloudUrl: string,    云存储上传下载
 * aiPictureUrl: string AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 */

// const requestAddrGather = {
//   baseURL: 'http://192.168.40.114:29000',
//   webscoket: 'ws://192.168.40.203:9600',
//   cloudUrl: 'http://124.114.148.148:29601',
//   aiPictureUrl: 'http://192.168.40.203:9600',
// }

////----------------------------------------------------------》000》》》测试环境接口访问配置《《《《-------------------------------------------------------------------------------////
/**
 * 测试环境 接口请求地址
 * @type {{
 * baseURL: string,      业务访问接口
 * webscoket: string,    即时通讯连接地址
 * cloudUrl: string,     云存储上传下载
 * aiPictureUrl: string  AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 */
const requestAddrGather = {
  baseURL: 'https://dsj.beidouenv.com',
  cloudUrl: 'https://dsj.beidouenv.com',
  webscoket: 'ws://dsj.beidouenv.com/wss/',
  aiPictureUrl: 'https://dsj.beidouenv.com'
}


////-------------------------------------------------------》》》》开发测试公共接口访问配置环境《《《《---------------------------------------------------------------------------////
/**
 * 开发测试公用环境： arcgis api和遥感处理工具
 * @type {{
 * uploadFileUrl: string,   云处理工具访问接口
 * toolAddressUrl: string,  天地图底图访问地址
 * tiandituUrl: string,     天地图底图图层服务负载
 * subDomainsUrl: string[]  arcgis工具处理完成之后下载地址
 * }}
 *
 */
// const publicAddrGather = {
//   toolAddressUrl: 'https://portal.shaanxieco.com',
//   tiandituUrl: 'https://{subDomain}.tianditu.gov.cn',
//   subDomainsUrl: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
//   uploadFileUrl: 'http://1.82.253.168:30242'
// }

////----------------------------------------------------------》》》》正式环境《《《《------------------------------------------------------------------------------////
/**
 *
 * 正式环境 接口访问地址
 *
 * @type {{
 * baseURL: string,      业务访问接口
 * webscoket: string,    即时通讯连接地址
 * cloudUrl: string,     云存储上传下载
 * aiPictureUrl: string  AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 *
 * @type {{
 * uploadFileUrl: string,   云处理工具访问接口
 * toolAddressUrl: string,  天地图底图访问地址
 * tiandituUrl: string,     天地图底图图层服务负载
 * subDomainsUrl: string[]  arcgis工具处理完成之后下载地址
 * }}
 */
// const requestAddrGather = {
//   baseURL: 'https://www.shaanxieco.com',
//   webscoket: 'wss://www.shaanxieco.com/wss/',
//   cloudUrl: 'https://www.shaanxieco.com',
//   aiPictureUrl: 'https://www.shaanxieco.com',
// }
const publicAddrGather = {
  toolAddressUrl: 'https://portal.shaanxieco.com',
  tiandituUrl: 'https://{subDomain}.tianditu.gov.cn',
  subDomainsUrl: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
  uploadFileUrl: 'https://www.shaanxieco.com'
}


module.exports = {
  ...requestAddrGather,
  ...publicAddrGather,
  // 数据请求的接收方式
  contentType: 'application/json;charset=UTF-8',
  // 数据请求的最大时间 1个小时
  requestTimeout: 3600000,
  successCode: [200, 0, '200', '0'],
  statusName: 'code',
  messageName: 'msg'
}
